// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bC_k4";
export var caseStudyBackground__lineColor = "bC_kW";
export var caseStudyHead__imageWrapper = "bC_kT";
export var caseStudyProjectsSection = "bC_k5";
export var caseStudyQuote__bgLight = "bC_k2";
export var caseStudyQuote__bgRing = "bC_k1";
export var caseStudySolution__ring = "bC_kZ";
export var caseStudyVideo__ring = "bC_k8";
export var caseStudy__bgDark = "bC_kS";
export var caseStudy__bgLight = "bC_kR";